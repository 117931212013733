const init = (): void => {
  const inverteds = document.querySelectorAll('*[data-inverted]') as NodeListOf<Element>

  inverteds.forEach((element: Element): void => {
    const inverted = element as HTMLElement
    const toggle = inverted.querySelector('*[data-inverted-toggle]') as HTMLInputElement

    const invertedToggle = (): void => {
      if (toggle.checked == false) {
        inverted.dataset.inverted = 'before'
      } else {
        inverted.dataset.inverted = 'after'
      }
    }

    invertedToggle()

    toggle.addEventListener('change', invertedToggle as EventListener)
  })
}

export default { init }
