const init = (): void => {
  const gift = document.querySelector('*[data-gift]') as HTMLElement

  if (!gift) return

  const quiz = document.querySelector('#quiz') as HTMLElement

  const giftToScroll = (): void => {
    if (gift && quiz && screen.height >= quiz.getBoundingClientRect().top) {
      gift.classList.add('opacity-0', 'invisible')
    } else {
      gift.classList.remove('opacity-0', 'invisible')
    }
  }

  const giftRemove = (): void => {
    setTimeout((): void => gift.remove(), 100)
  }

  giftToScroll()

  document.addEventListener('scroll', giftToScroll as EventListener)
  gift.addEventListener('click', giftRemove as EventListener)
}

export default { init }
