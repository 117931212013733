import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs } from 'swiper'
import media from './functions/media'
import quiz from './quiz'

declare global {
  interface Window {
    Swiper: typeof Swiper
  }
}

Swiper.use([Navigation, Pagination, Scrollbar, Autoplay, Grid, Thumbs])
Swiper.defaults.touchStartPreventDefault = false
window.Swiper = Swiper

const init = (): void => {
  new window.Swiper('.result-slider .swiper', {
    slidesPerView: 'auto',
    spaceBetween: 40,
    speed: 10000,
    loop: true,
    allowTouchMove: false,
    watchSlidesProgress: true,

    autoplay: {
      delay: 0,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
  }) as Swiper

  new window.Swiper('.quiz-slider .quiz-swiper', {
    navigation: {
      prevEl: '.quiz-slider .quiz-prev',
      nextEl: '.quiz-slider .quiz-next',
    },

    pagination: {
      el: '.quiz-slider .swiper-pagination',
      type: 'custom',

      renderCustom: (swiper: Swiper, current: number, total: number): string => {
        return `${current}/${total}`
      },
    },

    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 30,
    allowTouchMove: false,
    watchSlidesProgress: true,

    on: {
      slideChange: (swiper) => {
        quiz.checkQuizSlide(swiper.visibleSlides[0])

        if (swiper.visibleSlides[0] == swiper.slides[swiper.slides.length - 1]) {
          swiper.el.closest('[data-quiz]').setAttribute('data-quiz-end', '')
        } else {
          swiper.el.closest('[data-quiz]').removeAttribute('data-quiz-end')
        }
      },
    },
  }) as Swiper

  new window.Swiper('.transport-slider .transport-swiper', {
    navigation: {
      prevEl: '.transport-slider .transport-prev',
      nextEl: '.transport-slider .transport-next',
    },

    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 30,
    watchSlidesProgress: true,
    loop: true,
    grabCursor: true,

    on: {
      slideChange: (swiper) => {
        const slide = swiper.visibleSlides[0] as HTMLElement
        const slider = slide.closest('[data-quiz]') as HTMLElement
        const input = slider.querySelector('*[data-quiz-input]') as HTMLInputElement

        input.value = String(slide.dataset.slideActive)
      },
    },
  }) as Swiper
}

export default { init }
