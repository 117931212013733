// Scripts
import fancybox from './ts/fancybox'
import sliderSwiper from './ts/slider-swiper'
import yandexMap from './ts/yandex-map'
import smoothScroll from './ts/smooth-scroll'
import scrollHeader from './ts/scroll-header'
import currentTab from './ts/current-tab'
import sidebar from './ts/sidebar'
import gift from './ts/gift'
import scrollTo from './ts/scroll-to'
import animation from './ts/animation'
import waved from './ts/waved'
import inverted from './ts/inverted'
import listing from './ts/listing'
import parallax from './ts/parallax'
import filter from './ts/filter'
import formSubmit from './ts/form-submit'
import inputs from './ts/inputs'
import phoneMask from './ts/phone-mask'
import quiz from './ts/quiz'
import compare from './ts/compare'
import preloader from './ts/preloader'

// Style
import './scss/style.scss'

// Connection
window.addEventListener('DOMContentLoaded', ((): void => {
  fancybox.init()
  sliderSwiper.init()
  yandexMap.init()
  currentTab.init()
  smoothScroll.init()
  scrollHeader.init()
  sidebar.init()
  gift.init()
  scrollTo.init()
  animation.init()
  waved.init()
  inverted.init()
  listing.init()
  parallax.init()
  filter.init()
  formSubmit.init()
  inputs.init()
  phoneMask.init()
  quiz.init()
  compare.init()
  preloader.init()
}) as EventListener)
